import Api from "@/services/Index";

const registrationNumbersByFaculty = async (config) => {
    return Api.get("/student/reports/registration-numbers-by-faculty", config);
};

const numberOfStudentByStatus = async (config) => {
    return Api.get("/student/reports/number-of-students-by-status", config);
};

const studentsShouldTakeCourse = async (config) => {
    return Api.get("/student/slots/should-take-course", config);
};


const studentsShouldTakeCourses = async (config) => {
    return Api.get("/student/slots/should-take-courses", config);
};

const studentsShouldTakeElective = async (config) => {
    return Api.get("/student/slots/should-take-elective", config);
};

const semesterStatusAnalysis = async (config) => {
    const responseType =
        config.params.response_type == "page" ? "" : "arraybuffer";
    return Api.get("/student/reports/semester-status-analysis", {
        ...config,
        responseType,
    });
};
const undergraduateTransferYokReport = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('undergraduate/transfers/yok-report',{ responseType: 'arraybuffer'});
}

const minorBranchDismissal = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('minor/application/course-not-registration-report',{ responseType: 'arraybuffer'});
}
const minorGraduateBranchDismissalReport = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/application/major-a-graduate-major-y-active-report',{ responseType: 'arraybuffer'});
}
const dischargeReport = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/double/major/course-not-registration-report',{ responseType: 'arraybuffer'});
}

const studentStatusChanges = async (config) => {
    return Api.get('/student/reports/status-changes', config);
}


export default {
    registrationNumbersByFaculty,
    numberOfStudentByStatus,
    studentsShouldTakeCourse,
    studentsShouldTakeElective,
    semesterStatusAnalysis,
    undergraduateTransferYokReport,
    studentsShouldTakeCourses,
    minorBranchDismissal,
    minorGraduateBranchDismissalReport,
    dischargeReport,
    studentStatusChanges
};
