<template>
    <div>
        <ValidationObserver ref="storeForm">
        <b-row>
            <b-col cols="12" md="3">
                <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                    <b-form-group :label="$t('faculty')">
                        <faculty-selectbox v-model="filter.faculty_code"
                                           :validate-error="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="3">
                <ValidationProvider name="departmentCode" rules="" v-slot="{valid, errors}">
                    <b-form-group :label="$t('department')">
                        <department-selectbox
                            :validateError="errors[0]"
                             v-model="filter.department_code"
                            :faculty_code="filter.faculty_code">
                        </department-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="3">
                <ValidationProvider name="programCode" rules="" v-slot="{valid, errors}">
                    <b-form-group :label="$t('program')">
                        <program-selectbox
                            :validateError="errors[0]"
                             v-model="filter.program_code"
                            :faculty_code="filter.faculty_code"
                            :department_code="filter.department_code"
                            >
                        </program-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </b-col>

            <b-col cols="12" md="2">
                <b-form-group class="mb-0">
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>

        </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
import qs from 'qs'
import DoubleMajorService from "@/services/DoubleMajorService";

export default {
    data() {
        return {
            responseType: 'excel',
            filter: {}
        }
    },
    methods: {
      async  getReport() {
            let isValid = await  this.$refs.storeForm.validate()
            if(isValid){
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                return DoubleMajorService.downloadOverlapStudentReport(config)
                    .then((response) => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    })
            }

        }
    }
}
</script>
