<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_reports')" :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_reports')" :isFilter="false"/>
            </template>
            <h6>{{ $t('select_report_type') }}</h6>
            <div class="border rounded p-5 mb-3">
                <multiselect
                    :clear-on-select="true"
                    :close-on-select="true"
                    :deselect-label="''"
                    :options="computeReportTypes"
                    :placeholder="$t('select')"
                    :select-label="''"
                    :selected-label="''"
                    label="text"
                    track-by="value"
                    v-model="reportType"
                />
            </div>
            <div class="col-12" v-if="reportType">
                <h6>
                    <span v-if="reportType && reportType.text">
                        {{ reportType.text }}
                    </span>
                </h6>
                <div class="border rounded p-5">
                    <component v-if="reportType && reportType.value" :is="reportType.value"/>
                </div>
            </div>
        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
// Data
import ReportTypes from '../../data/StudentReportTypes'
// Pages
import MissingDocumentsReport from './missingDocumentsReport/Index'
import StudentNotesReport from './studentNotesReport/Index'
import FullScholarshipStudents from './fullScholarshipStudents'
import RegistrationNumbersByFaculty from './registrationNumbersByFaculty/Index'
import NumberOfStudentsByStatus from './numberOfStudentsByStatus/Index'
import YokRegistrationStatusReport from './yokRegistrationStatusReport/Index'
import SummerSchoolReport from './summerSchoolReport/Index'
import semesterStatusAnalysis from './semesterStatusAnalysisReport/Index'
import examOverlapReport from './examOverlapReport/index'
import studentsShouldTakeCourse from './studentsShouldTakeCourse/Index'
import studentsShouldTakeCourses from './studentsShouldTakeCourses/Index'
import studentsShouldTakeElective from './studentsShouldTakeElective/Index'
import doubleMajorTerminationReport from './doubleMajorTerminationReport/Index.vue'
import doubleMajorDismissalReport from './doubleMajorDismissalReport/Index.vue'
import studentOverlapReport from '@/modules/reports/pages/student/studentOverlapReport/Index.vue'
import undergraduateTransferYokReport from "@/modules/reports/pages/student/undergraduateTransferYokReport/Index.vue"
import minorApplicationsYokReport from './minorApplicationsYokReport/index'
import doubleMajorYokReport from './doubleMajorYokReport/index'
import verticalTransition from './verticalTransition/Index.vue'
import minorBranchDismissal from './minorBranchDismissal/Index.vue'
import majorGraduateMinorBranch from './majorGraduateMinorBranch/Index.vue'
import dischargeReport from './dischargeReport/Index.vue'
import studentStatusChanges from './studentStatusChanges/Index.vue'
import educationInformationReporting from './educationInformationReporting/Index.vue'
import doubleMajorLeaveWithConsentReport from './doubleMajorLeaveWithConsentReport/Index.vue'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        MissingDocumentsReport,
        doubleMajorDismissalReport,
        StudentNotesReport,
        doubleMajorTerminationReport,
        FullScholarshipStudents,
        RegistrationNumbersByFaculty,
        NumberOfStudentsByStatus,
        YokRegistrationStatusReport,
        SummerSchoolReport,
        examOverlapReport,
        studentsShouldTakeCourse,
        studentsShouldTakeElective,
        semesterStatusAnalysis,
        studentOverlapReport,
        undergraduateTransferYokReport,
        minorApplicationsYokReport,
        doubleMajorYokReport,
        studentsShouldTakeCourses,
        verticalTransition,
        minorBranchDismissal,
        majorGraduateMinorBranch,
        dischargeReport,
        studentStatusChanges,
        educationInformationReporting,
        doubleMajorLeaveWithConsentReport,
    },
    metaInfo() {
        return {
            title: this.$t('student_reports')
        }
    },
    data() {
        return {
            reportType: null
        }
    },
    computed: {
        computeReportTypes() {
            return ReportTypes().filter((item) => {
                return this.checkPermission(item.permission)
            })
        }
    }
}
</script>
