<template>
    <div>
        <ValidationObserver ref="form">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox
                                :multiple="false"
                                :validate-error="errors[0]"
                                :setActive="true"
                                v-model="filter.semester_id">
                            </semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="response_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('report_type')">
                            <multi-selectbox :allow-empty="false" :multiple="false"
                                             :validate-error="errors[0]" :options="responseTypeOptions"
                                             v-model="filter.response_type"></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <processing-button @click="getReport" label="get_report"
                                       :processing="processing"></processing-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Components
// Services
import StudentReportService from '@/services/StudentReportService'

// Other
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import ProcessingButton from "@/components/elements/ProcessingButton";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    components: {
        ProcessingButton,
        MultiSelectbox,
        SemestersSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'},
            ],
            processing: false,
            filter: {
                response_type: 'page'
            },
            reportName: this.$t('registration_numbers_by_faculty_report')
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.processing = true;
                let config = {params: this.filter};
                let isArrayBuffer = false;
                if (this.filter.response_type != 'page') {
                    config.responseType = 'arraybuffer';
                    isArrayBuffer = false;
                }

                StudentReportService.registrationNumbersByFaculty(config).then(this.callback).catch(e => {
                    this.showErrors(e, this.$refs.form, isArrayBuffer);
                }).finally(() => {
                    this.processing = false;
                });
            }
        },
        callback(response) {
            if (this.filter.response_type == 'page') {
                this._openFile(response);
            }
            else if (this.filter.response_type == 'excel') {
                this._downloadFile(response, this.reportName + '.xlsx');
            }
            else if (this.filter.response_type == 'pdf') {
                this._downloadFile(response, this.reportName + '.pdf');
            }
        }
    }
}
</script>
